<template>
  <div class="profile-addresses">
    <!-- <v-container> -->
    <v-row>
      <v-col class="hidden-sm-and-down" cols="0" md="3">
        <MenuNavigator class="menu-navigator" />
        <v-divider class="my-5"></v-divider>
        <SupportCard />
      </v-col>
      <v-col cols="12" md="9">
        <ProfileTitle class="mb-3 mb-sm-0" :category="category" />
        <AddressGrid
          :addresses="currentAddresses"
          @refresh="fetchAddresses"
        ></AddressGrid>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import MenuNavigator from "@/components/profile/MenuNavigator.vue";
import SupportCard from "@/components/cards/SupportCard.vue";
import AddressGrid from "@/components/delivery/AddressGrid.vue";
import ProfileTitle from "@/components/profile/ProfileTitle.vue";

import categoryMixins from "~/mixins/category";

import DeliveryService from "@/service/grosmarketCustomService";

export default {
  name: "ProfileAddress",
  components: {
    MenuNavigator,

    SupportCard,
    AddressGrid,
    ProfileTitle
  },
  mixins: [categoryMixins],
  data() {
    return {
      currentAddresses: [],
      newAddress: {
        addressId: -1,
        addressName: "",
        address1: "",
        address2: "",
        addressNumber: "",
        postalcode: "",
        city: "",
        province: ""
      }
    };
  },
  methods: {
    async fetchAddresses() {
      let vm = this;
      var data = await DeliveryService.getAllAddress();
      vm.currentAddresses = data.addresses;
    },
    async removeAddress(addressId) {
      const res = await this.$dialog.confirm({
        text: global.vm.$t("message.removeAddress")
      });
      if (res) {
        await DeliveryService.removeAddress(addressId);
        await this.fetchAddresses();
      }
    },
    selectAddress(address) {
      DeliveryService.selectAddress(address.addressId);
    }
  },
  mounted() {
    this.fetchAddresses();
  }
};
</script>
